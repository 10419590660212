import { useState, useEffect, useRef, ChangeEvent } from 'react'
import { ReactComponent as ArrowRightIcon } from '../../../images/SectionalTest/st-arrow-right-icon.svg'
import AudioSectional from '../../../components/SectionalTest/AudioSectional'
import { useNavigate } from 'react-router-dom'
import { remvoveTestInformation } from '../../../utils/utils'
import { ExtendedQuestion } from '../../../models/question.model'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import LoadingMessage from '../../../components/Items/LoadingMessage'
import { useAuth } from '../../../providers/AuthProvider'
import DiffMatchPatch from 'diff-match-patch'
import Path from '../../../routes/Path'
import { addResultListeningMock, doTest1Update } from '../../../services/mockTest.services'
import {
  handleExceptionError,
} from '../../../utils/utils'

const WriteFromDictationMock = () => {
  const navigate = useNavigate()
  const [wordsCount, setWordsCount] = useState(0)

  const [timer, setTimer] = useState({ minutes: 40, seconds: 0 })
  const [question, setQuestion] = useState<ExtendedQuestion | undefined>(undefined)
  const [questionNumber, setQuestionNumber] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [buyId, setBuyId] = useState<number | undefined>(undefined)
  const [userAnswer, setUserAnswer] = useState('')
  const { userProfile } = useAuth()

   // Ref to store the elapsed time in seconds
   const elapsedTimeRef = useRef(0)

   useEffect(() => {
     setTimeout(() => {
      const resultData = localStorage.getItem("resultMockPTE")
      const buyIdStorage = localStorage.getItem("buyIdPTEMock")
      if (buyIdStorage) {
        setBuyId(Number(buyIdStorage))
      }

      if (resultData) {
        const parsedResultData = JSON.parse(resultData)
        setQuestion(parsedResultData.mockqestion[0])
        setQuestionNumber(parsedResultData.row)
        setTotalQuestion(Number(parsedResultData.allcount))
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
   }, [])

  const formattedTime = `${timer.minutes.toString().padStart(2, '0')}:${timer.seconds.toString().padStart(2, '0')}`

  // Function to calculate the total elapsed time in seconds
  const calculateElapsedTime = () => {
   return elapsedTimeRef.current
 }

  // Function to start the timer countdown
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined

    if (timer.minutes === 0 && timer.seconds === 0) {
      // Timer has reached 00:00, stop the timer
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        elapsedTimeRef.current += 1

        if (timer.seconds === 0) {
          setTimer({ minutes: timer.minutes - 1, seconds: 59 })
        } else {
          setTimer({ ...timer, seconds: timer.seconds - 1 })
        }
      }, 1000)
    }

    // Cleanup function to clear the interval when component unmounts or timer stops
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [timer])

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const text = e.target.value
    setUserAnswer(text.trim())
    const wordCount = text.trim().split(/\s+/).filter(Boolean).length
    setWordsCount(wordCount)
  }


  const calculateListeningFinal = (operation: string, text: string) => {
    const dmp = new DiffMatchPatch()
    const diffs = dmp.diff_main(operation, text)

    let r = 0
    let u = 0
    let f = 0

    for (let i = 0; i < diffs.length; i++) {
      diffs[i][0] === 0 ? u += diffs[i][1].length : diffs[i][0] === -1 ? r += diffs[i][1].length : f += diffs[i][1].length
    }

    const d = u * 100 / (r + u + f)
    return parseFloat(d.toString()).toFixed(2)
  }

  const handleSubmitAnswer = async () => {
    setIsSubmitted(true)
    const elapsedTime = calculateElapsedTime()

    if (question && question.description && userProfile && userProfile.userId && buyId) {  
      try {
        const formattedUserAnswer = userAnswer.trim().toLowerCase()
        const originalAnswerString = question.description.trim().toLowerCase()
        const listeningFinalScore = calculateListeningFinal(originalAnswerString, formattedUserAnswer)

        const addResultResponse = await addResultListeningMock(
          question.description,
          userProfile.userId,
          question.testId,
          question.id,
          question.categoryId,
          userAnswer,
          '',
          Number(listeningFinalScore),
        )
      
        if (addResultResponse.data.success) {
          const getQuestionResponse = await doTest1Update(
            buyId,
            question.testId,
            userProfile.userId,
            questionNumber,
            4,
            String(elapsedTime),
            String(elapsedTime),
            totalQuestion,
            'Skip'
          )
      
          if (getQuestionResponse.data.success) {
            if (getQuestionResponse.data.message === 'Calculate report card fetch successfully') {
              remvoveTestInformation(1)
              navigate(
                Path.historyDetailsMock.path
                  .replace(":buyId", String(getQuestionResponse.data.ubuyid))
                  .replace(":testId", String(getQuestionResponse.data.testid))
              )
            } else {
              localStorage.setItem('resultMockPTE', JSON.stringify(getQuestionResponse.data.result))
      
              if (getQuestionResponse.data.category_id === '24') {
                navigate(0) // Reload the same page
              }
            }
          } else {
            console.error(getQuestionResponse.data.message)
          }
        } else {
          console.error(addResultResponse.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question or question description or buyId not found!')
    }
  }

  const handleSkip = async () => {
    setIsSubmitted(true)
    const elapsedTime = calculateElapsedTime()

    if (question && userProfile && userProfile.userId && buyId) {
      try {
        const getQuestionResponse = await doTest1Update(
          buyId,
          question.testId,
          userProfile.userId,
          questionNumber,
          4,
          String(elapsedTime),
          String(elapsedTime),
          totalQuestion,
          'Skip'
        )
    
        if (getQuestionResponse.data.success) {
          if (getQuestionResponse.data.message === 'Calculate report card fetch successfully') {
            remvoveTestInformation(1)
            navigate(
              Path.historyDetailsMock.path
                .replace(":buyId", String(getQuestionResponse.data.ubuyid))
                .replace(":testId", String(getQuestionResponse.data.testid))
            )
          } else {
            localStorage.setItem('resultMockPTE', JSON.stringify(getQuestionResponse.data.result))
      
            if (getQuestionResponse.data.category_id === '24') {
              navigate(0) // Reload the same page
            }
          }
        } else {
          console.error(getQuestionResponse.data.message)
        }
      } 
      catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question or buyId not found!')
    }
  }

  return (
    <div className='min-h-[80vh]'>
      {
        isLoading ? (
          <LoadingMessage message='Loading question...'/> 
        ) : errorMsg ? (
          <ErrorMessage message={errorMsg} />
        ) : (
          <div className="st-wfd w-full pb-4 shadow-question-card mx-auto font-landingPrimary">
            <div className="st-wfd__header flex justify-between gap-2 p-4 mb-4 bg-info rounded-lg text-white text-h5r">
              <p className="st-wfd__header__title">
                Listening Section - Write From Dictation (#
                {question?.id})
              </p>
              <p className="st-wfd__header__number">({questionNumber - 44} Out of 18)</p>
            </div>
            <div className="st-sst__timer w-[6.25rem] h-[3.5rem] bg-primary-2 mb-6 rounded-lg flex justify-center items-center mx-auto">
              <p className="text-h2 text-white">{formattedTime}</p>
            </div>
            <div className="st-wfd__instruction text-h4m px-4 mb-4">
              {question?.shortTitle}
            </div>
            <AudioSectional audioUrl={question && question.audio ? question.audio : ''} />
            <div className="st-wfd__answer flex flex-col gap-2 lg:px-[10rem] px-4 mb-4">
              <label className="st-swt__answer__label text-h5b">Your answer</label>
              <textarea
                className="w-full h-[5rem] text-bodyr border border-1 border-[#D9D9D9] p-2 resize-none"
                placeholder="Write here..."
                onChange={handleTextChange}
              />
            </div>
            <p className="st-wfd__words-count text-bodyr lg:px-[10rem] px-4 mb-4">
              Words count: {wordsCount}
            </p>
            <div className='w-full flex justify-center gap-4'>
              <button 
                className={`flex text-bodyr text-white items-center justify-centers gap-2 py-2 px-6 rounded-lg 
                  ${(wordsCount === 0 || isSubmitted) ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
                onClick={handleSubmitAnswer}
                disabled={wordsCount === 0 || isSubmitted}
              >
                Save and Next
                <ArrowRightIcon />
              </button>
              <button 
                className={`flex items-center py-2 px-6 rounded-lg 
                  ${isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-info cursor-pointer'}`}
                disabled={isSubmitted}
                onClick={handleSkip}
              >
                <p className="text-bodyr text-white">Skip</p>
              </button>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default WriteFromDictationMock
