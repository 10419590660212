import { UserTestResultHistory } from "../../../models/userTestResultHistory"

interface HistoryDetailsReadingMockProps {
  testHistoryReading: UserTestResultHistory[]
}

const HistoryDetailsReadingMock: React.FC<HistoryDetailsReadingMockProps> = ({
  testHistoryReading,
}) => {
  // Function to create markup for dangerouslySetInnerHTML
  const createMarkup = (html: string) => {
    return { __html: html }
  }

  return (
    <div className="history-details-reading w-full h-full mt-[1rem] p-2 overflow-x-auto font-landingPrimary">
      <div className="min-w-full inline-block align-middle overflow-hidden">
        <table className="min-w-full table-fixed">
          <thead>
            <tr className="text-bodyr text-neutrals-2 h-[3.75rem]">
              <th className="w-[10rem] p-2" align="left">Test</th>
              <th className="w-[20rem] p-2" align="left">Answer</th>
              <th className="w-[20rem] p-2" align="left">Your Answer</th>
              <th className="w-[5.2rem] p-2" align="left">Grammar</th>
              <th className="w-[5.2rem] p-2" align="left">Overall Score</th>
            </tr>
          </thead>
          <tbody>
            {testHistoryReading.length > 0 ? (
              testHistoryReading.map((data, index) => (
                <tr
                  key={index}
                  className={`text-bodyr ${index % 2 === 0 ? 'bg-[#FAFAFA]' : ''}`}
                >
                  <td className="p-2 text-bodym rounded-l-xl">{data.section}</td>
                  <td
                    className="p-2 text-bodyr"
                    dangerouslySetInnerHTML={createMarkup(data.finalKeywords)}
                  ></td>
                  <td
                    className="p-2 text-bodyr"
                    dangerouslySetInnerHTML={createMarkup(data.useAnswer)}
                  ></td>
                  <td className="p-2 text-bodyr">{data.grammar}</td>
                  <td className="p-2 text-bodyr rounded-r-xl">{data.totalScore}</td>
                </tr>
              ))
            ) : (
              <tr className="bg-[#FAFAFA]">
                <td colSpan={5} className="p-4 text-center text-bodyr text-red-400 font-bold">
                  No reading test history available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default HistoryDetailsReadingMock
