import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import historyTestAnalytics from '../../../images/MockTest/history-test-analytics.svg'
import AnalyticMockModal from './AnalyticsMockModal'
import historyTestInfo from '../../../images/MockTest/history-test-info.svg'
import { getTestHistoryMock, getReportHistoryDetails } from '../../../services/testHistory.services'
import TestHistoryMock from '../../../models/testHistoryMock.model'
import ReportHistoryDetails from '../../../models/reportHistoryDetails.model'
import LoadingMessage from '../../Items/LoadingMessage'
import ErrorMessage from '../../Items/ErrorMessage'
import { useAuth } from '../../../providers/AuthProvider'
import Path from '../../../routes/Path'

const TestHistoryMockSection = () => {
  const { userProfile } = useAuth()
  const [testHistory, setTestHistory] = useState<TestHistoryMock[]>([])
  const [reportHistoryDetails, setReportHistoryDetails] = useState<ReportHistoryDetails>({
    speaking: 10,
    writing: 10,
    listening: 10,
    reading: 10,
    average: 10,
    vocabulary: 10,
    spelling: 10,
    grammar: 10,
    pronunciation: 10,
    form: 10,
    fluency: 10
  })
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingCursor, setIsLoadingCursor] = useState(false)
  const navigate = useNavigate()
  const cursorStyle = isLoadingCursor ? 'cursor-wait' : 'cursor-default'

  useEffect(() => {
    if (userProfile) {
      getTestHistoryMock(userProfile.userId)
        .then(response => {
          if(response.data.success === true) {
            setTestHistory(response.data.test)
          }
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false)
          }, 2000)
        })
    }
  }, [userProfile])

  const itemsPerPage = 3
  const [showModal, setShowModal] = useState(false)
  const [selectedTest, setSelectedTest] = useState<TestHistoryMock | null>(
    null,
  )

  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(testHistory.length / itemsPerPage)

  const startIndex = (currentPage - 1) * itemsPerPage
  const currentItems: TestHistoryMock[] = testHistory.slice(
    startIndex,
    startIndex + itemsPerPage,
  )
  const goToPage = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleViewAnalytics = (test: TestHistoryMock) => {
    setIsLoadingCursor(true)
    setSelectedTest(test)
    getReportHistoryDetails(test.userId, test.testId, test.id)
      .then(response => {
        if(response.data.success === true) {
          setReportHistoryDetails({
            speaking: response.data.final_data.score_speaking_section,
            writing: response.data.final_data.score_writing_section,
            listening: response.data.final_data.score_listening_section,
            reading: response.data.final_data.score_reading_section,
            average: response.data.average,
            vocabulary: response.data.final_data.score_vocab,
            grammar: response.data.final_data.score_grammar,
            spelling: response.data.final_data.score_spelling,
            pronunciation: response.data.final_data.score_pronunciation,
            fluency: response.data.final_data.score_fluency,
            form: response.data.final_data.score_form
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoadingCursor(false)
          setShowModal(true)
        }, 2000)
      })
  }

  return (
    <div className={`test-history-mock-section ${cursorStyle}`}>
      <div className="w-full font-landingPrimary">
        <p className="text-h1 mb-[2rem]">Test History & Analysis</p>
      </div>
      {
        isLoading ? (
          <div className="h-[4rem] mt-[2rem]">
            <LoadingMessage message='Loading test history ...'/>
          </div>
        ) : 
          testHistory.length !== 0 ? 
        (
          <>
            <div className="w-full font-landingPrimary">
              <div className="grid grid-cols-6 text-h5m text-neutrals-2 my-4 text-center">
                <p>Number</p>
                <p>Thumbnail</p>
                <p>Title</p>
                <p>Purchase Date</p>
                <p>Duration</p>
                <p>Actions</p>
              </div>
              <div className="w-full text-center">
                {currentItems.map((testHistory: TestHistoryMock, index) => (
                  <div
                    key={testHistory.id}
                    className="grid grid-cols-6 gap-2 bg-white rounded-lg shadow-test-history-card py-4 mb-4 items-center"
                  >
                    <div className="text-bodyr text-neutrals-1">{index + 1}</div>
                    <div className="flex justify-center items-center">
                      <img
                        src={testHistory.details?.image}
                        alt="Test Thumbnail"
                        className="lg:w-[9.375rem] w-[4.5rem] lg:h-[9.375rem] h-[4.5rem] rounded-lg object-fit"
                      />
                    </div>
                    <div className="justify-center text-bodyr text-neutrals-1">
                      {testHistory.details?.name}
                    </div>
                    <div className="justify-center text-bodyr text-neutrals-1">
                      {testHistory.completeDate}
                    </div>
                    <div className="justify-center text-bodyr text-neutrals-1">
                      {testHistory.details?.duration} 
                    </div>
                    <div className="flex justify-center items-center gap-2">
                      <button
                        className=" bg-info hover:scale-105 hover:cursor-pointer text-bodyr text-white py-2 px-4 rounded-lg"
                        onClick={() => handleViewAnalytics(testHistory)}
                      >
                        <div className="flex">
                          <img
                            src={historyTestAnalytics}
                            alt="History Test Analytics"
                            className="mr-1"
                          />
                          <span className="lg:inline hidden">View analytics</span>
                        </div>
                      </button>
                      <img
                        src={historyTestInfo}
                        alt="History Test Info"
                        className="mx-2 hover:scale-105"
                        onClick={() => navigate(
                          Path.historyDetailsMock.path
                            .replace(":buyId", String(testHistory.id))
                            .replace(":testId", String(testHistory.testId))
                        )}
                      />
                    </div>
                    <div className="col-span-6">
                      {selectedTest?.id === testHistory.id && (
                        <AnalyticMockModal
                          show={showModal}
                          onClose={() => setShowModal(false)}
                          test={reportHistoryDetails}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex lg:gap-4 gap-2 justify-end items-center mt-4">
              <button
                onClick={() => goToPage(currentPage - 1)}
                disabled={currentPage === 1}
                className={`${currentPage === 1 ? 'text-[#D9D9D9]' : 'hover:text-info'}`}
              >
                {'<'}
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  className={`px-2 py-1 ${currentPage === index + 1 ? 'text-[#1890FF] border border-1 border-[#1890FF]' : 'text-gray-700'}`}
                  onClick={() => goToPage(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => goToPage(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={`${currentPage === totalPages ? 'text-[#D9D9D9]' : 'hover:text-info'}`}
              >
                {'>'}
              </button>
            </div>     
          </>
        ) : (
          <div className="h-[4rem] mt-[2rem]">
            <ErrorMessage message='You have not completed any tests!'/>
          </div>
        )
      }
    </div>
  )
}

export default TestHistoryMockSection
