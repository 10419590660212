import axiosInstance from "../configs/axiosConfig"

//System Requirement - Generate random questions from mock test
//Writing
export function startTestNow3(userId: number) {
    const data = {
        user_id: userId
    }

    return axiosInstance.post(`/sectionaltest/start-test-now-3`, data)
}

export function doTest3(buyId: number, testId: number, userId: number) {
    const data = {
        ubuyid: buyId,
        testid: testId,
        user_id: userId,
    }

    return axiosInstance.post(`/sectionaltest/do-test-3`, data)
}

export function doTest3Update(
    buyId: number, 
    testId: number, 
    userId: number, 
    row: number, 
    questionType: number, 
    speakingUpdatedTime: string, 
    updatedTime: string,
    allcount: number, 
    butNext: string
) {
    const data: any = {
        ubuyid: buyId,
        testid: testId,
        user_id: userId,
        row: row,
        updatedtime: updatedTime,
        allcount: allcount,
        but_next: butNext
    }

    if(questionType === 1) {
        data['speakingUpdatedTime'] = speakingUpdatedTime
    } 
    if(questionType === 3) {
        data["readingUpdatedTime"] = speakingUpdatedTime
    }
    if(questionType === 4) {
        data["listening_section_timer"] = speakingUpdatedTime
    }

    return axiosInstance.post(`/sectionaltest/do-test-3`, data)
}

export function addResultWritingSectional(mocqdesc: string, userId: number, testId: number, testQuestionId: number, testCategoryId: number, buyId: number, userAnswer: string) {
    const data = {
        mocqdesc: mocqdesc || "none",
        userId: userId,
        testId: testId,
        testQuestionId: testQuestionId,
        testCategoryId: testCategoryId,
        buyId: buyId,
        userAnswer: userAnswer,
    }

    return axiosInstance.post(`/sectional-test/writing`, data)
}
//Reading
export function startTestNow4(userId: number) {
    const data = {
        user_id: userId
    }

    return axiosInstance.post(`/sectionaltest/start-test-now-4`, data)
}

export function doTest4(buyId: number, testId: number, userId: number) {
    const data = {
        ubuyid: buyId,
        testid: testId,
        user_id: userId,
    }

    return axiosInstance.post(`/sectionaltest/do-test-4`, data)
}

export function doTest4Update(
    buyId: number, 
    testId: number, 
    userId: number, 
    row: number, 
    questionType: number, 
    speakingUpdatedTime: string, 
    updatedTime: string,
    allcount: number, 
    butNext: string
) {
    const data: any = {
        ubuyid: buyId,
        testid: testId,
        user_id: userId,
        row: row,
        updatedtime: updatedTime,
        allcount: allcount,
        but_next: butNext
    }

    if(questionType === 1) {
        data['speakingUpdatedTime'] = speakingUpdatedTime
    } 
    if(questionType === 3) {
        data["readingUpdatedTime"] = speakingUpdatedTime
    }
    if(questionType === 4) {
        data["listening_section_timer"] = speakingUpdatedTime
    }

    return axiosInstance.post(`/sectionaltest/do-test-4`, data)
}

export function addResultReadingSectional(mocqdesc: string, userId: number, testId: number, testQuestionId: number, testCategoryId: number, buyId: number, userAnswer: string, userAnswerStr: string) {
    const data = {
        mocqdesc: mocqdesc || "none",
        userId: userId,
        testId: testId,
        testQuestionId: testQuestionId,
        testCategoryId: testCategoryId,
        buyId: buyId,
        mark: testCategoryId === 12 ? parseFloat(userAnswer) : 0,
        userAnswer: userAnswer,
        userAnswerstr: userAnswerStr,
    }

    return axiosInstance.post(`/sectional-test/reading`, data)
}
//Listening
export function startTestNow5(userId: number) {
    const data = {
        user_id: userId
    }

    return axiosInstance.post(`/sectionaltest/start-test-now-5`, data)
}

export function doTest5(buyId: number, testId: number, userId: number) {
    const data = {
        ubuyid: buyId,
        testid: testId,
        user_id: userId,
    }

    return axiosInstance.post(`/sectionaltest/do-test-5`, data)
}

export function doTest5Update(
    buyId: number, 
    testId: number, 
    userId: number, 
    row: number, 
    questionType: number, 
    speakingUpdatedTime: string, 
    updatedTime: string,
    allcount: number, 
    butNext: string
) {
    const data: any = {
        ubuyid: buyId,
        testid: testId,
        user_id: userId,
        row: row,
        updatedtime: updatedTime,
        allcount: allcount,
        but_next: butNext
    }

    if(questionType === 1) {
        data['speakingUpdatedTime'] = speakingUpdatedTime
    } 
    if(questionType === 3) {
        data["readingUpdatedTime"] = speakingUpdatedTime
    }
    if(questionType === 4) {
        data["listening_section_timer"] = speakingUpdatedTime
    }

    return axiosInstance.post(`/sectionaltest/do-test-5`, data)
}

export function addResultListeningSectional(mocqdesc: string, userId: number, testId: number, testQuestionId: number, testCategoryId: number, buyId: number, userAnswer: string, userAnswerStr: string, m: number, listeningFinal: string) {
    const data = {
        mocqdesc: mocqdesc || "none",
        userId: userId,
        testId: testId,
        testQuestionId: testQuestionId,
        testCategoryId: testCategoryId,
        buyId: buyId,
        mark: m || parseFloat(listeningFinal) || parseFloat(userAnswer) || 0,
        userAnswer: userAnswer,
        userAnswerstr: userAnswerStr,
        listeningFinal: listeningFinal
    }

    return axiosInstance.post(`/sectional-test/listening`, data)
}

//Speaking
export function startTestNow2(userId: number) {
    const data = {
        user_id: userId
    }

    return axiosInstance.post(`/sectionaltest/start-test-now-2`, data)
}

export function doTest2(buyId: number, testId: number, userId: number) {
    const data = {
        ubuyid: buyId,
        testid: testId,
        user_id: userId,
    }

    return axiosInstance.post(`/sectionaltest/do-test-2`, data)
}

export function doTest2Update(
    buyId: number, 
    testId: number, 
    userId: number, 
    row: number, 
    questionType: number, 
    speakingUpdatedTime: string, 
    updatedTime: string,
    allcount: number, 
    butNext: string
) {
    const data: any = {
        ubuyid: buyId,
        testid: testId,
        user_id: userId,
        row: row,
        updatedtime: updatedTime,
        allcount: allcount,
        but_next: butNext
    }

    if(questionType === 1) {
        data['speakingUpdatedTime'] = speakingUpdatedTime
    } 
    if(questionType === 3) {
        data["readingUpdatedTime"] = speakingUpdatedTime
    }
    if(questionType === 4) {
        data["listening_section_timer"] = speakingUpdatedTime
    }

    return axiosInstance.post(`/sectionaltest/do-test-2`, data)
}

export function addResultSpeakingSectional( newRecordName: string, mocqdesc: string, userId: number, testId: number, testQuestionId: number, testCategoryId: number, buyId: number, recordDuration: number, userText: string, confidenceMark: number, fluencyMark: number) {
    const data = {
        newRecordName: newRecordName,
        mocqdesc: mocqdesc || "none",
        userId: userId,
        testId: testId,
        testQuestionId: testQuestionId,
        testCategoryId: testCategoryId,
        buyId: buyId,
        recordDuration: recordDuration,
        userText: userText,
        confidenceMark: confidenceMark,
        fluencyMark: fluencyMark,
    }

    return axiosInstance.post(`/sectional-test/speaking`, data)
}