import React, { useState, useEffect, useRef } from 'react'
import NextArrowSvg from '../../../images/Speaking/next-arrow.svg'
import { useNavigate } from 'react-router-dom'
import { ExtendedQuestion } from '../../../models/question.model'
import Option from '../../../models/option.model'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import LoadingMessage from '../../../components/Items/LoadingMessage'
import { useAuth } from '../../../providers/AuthProvider'
import { addResultReadingMock, doTest1Update } from '../../../services/mockTest.services'
import {
  handleExceptionError,
  navigateToMockTest,
} from '../../../utils/utils'
import ArrayUtils from '../../../utils/ArrayUtils'

interface NewArrayOptions {
  type: number
  data: string
  answer: string 
}

const MockReadingFillInTheBlanksQuestion = () => {
  const [originalDescription, setOriginalDescription] = useState('')
  const [newArrayOptions, setNewArrayOptions] = useState<NewArrayOptions[]>([])
  const [originalOptions, setOriginalOptions] = useState<Option[]>([]) //This one is the original options get from data
  const [options, setOptions] = useState<Option[]>([]) //This one will be changed if the drop or remove function is triggered
  const [totalOptionsInParagraph, setTotalOptionsInParagraph] = useState(0)
  const [question, setQuestion] = useState<ExtendedQuestion | undefined>(undefined)
  const [questionNumber, setQuestionNumber] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [timer, setTimer] = useState({ minutes: 40, seconds: 0 })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [buyId, setBuyId] = useState<number | undefined>(undefined)
  const containerParagraphRef = useRef<HTMLDivElement | null>(null)
  const navigate = useNavigate()
  const { userProfile } = useAuth()
  const elapsedTimeRef = useRef(0)

  // const handleGetInnerHTML = () => {
  //   if (containerParagraphRef.current) {
  //     const htmlString = containerParagraphRef.current.innerHTML 
  //     return htmlString
  //   } else {
  //     return ""
  //   }
  // }

  // Load question data
  useEffect(() => {
    setTimeout(() => {
      const resultData = localStorage.getItem("resultMockPTE")
      const buyIdStorage = localStorage.getItem("buyIdPTEMock")
      if (buyIdStorage) {
        setBuyId(Number(buyIdStorage))
      }

      if (resultData) {
        const parsedResultData = JSON.parse(resultData)
        setQuestion(parsedResultData.mockqestion[0])
        setOriginalDescription(parsedResultData.mockqestion[0].description || '')
        setNewArrayOptions(
          parsedResultData.mockqestion[0].newArray.map((part: NewArrayOptions) => ({
            ...part,
            answer: '', // Reset the answer to be empty
          }))
        )
        setQuestionNumber(parsedResultData.row)
        setTotalQuestion(Number(parsedResultData.allcount))
        setOriginalOptions(parsedResultData.option)
        setOptions(ArrayUtils.shuffleArray(parsedResultData.option))

        const totalOptionsInQuestion = () => {
          return parsedResultData.mockqestion[0].newArray.filter((part: NewArrayOptions) => part.type === 1).length
        }
        setTotalOptionsInParagraph(totalOptionsInQuestion)
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)

  }, [])

  // Timer functionality
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined
    if (timer.minutes === 0 && timer.seconds === 0) {
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        elapsedTimeRef.current += 1

        if (timer.seconds === 0) {
          setTimer({ minutes: timer.minutes - 1, seconds: 59 })
        } else {
          setTimer({ ...timer, seconds: timer.seconds - 1 })
        }
      }, 1000)
    }
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [timer])

  const dragItem = useRef<{ id: string, source: 'items' | 'newArray' } | null>(null)

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, id: string, source: 'items' | 'newArray') => {
    dragItem.current = { id, source }
    e.dataTransfer.effectAllowed = 'move'
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, targetIndex: number) => {
    e.preventDefault()
    if (!dragItem.current) return
  
    const { id, source } = dragItem.current
    const droppedItem = options.find(option => String(option.id) === id)
  
    if (newArrayOptions[targetIndex].answer) {
      return
    }
  
    if (source === 'items' && newArrayOptions[targetIndex].type === 1) {
      const existingAnswer = newArrayOptions[targetIndex].answer
      let updatedOptions = [...options]
  
      if (existingAnswer) {
        const originalOption = options.find(option => option.options === existingAnswer)
        if (originalOption) {
          updatedOptions = [...updatedOptions, originalOption]
        }
      }
  
      const updatedArray = newArrayOptions.map((part, index) => {
        if (index === targetIndex) {
          return { ...part, answer: droppedItem ? droppedItem.options : '' }
        }
        return part
      })
  
      updatedOptions = updatedOptions.filter(option => String(option.id) !== id)
      setNewArrayOptions(updatedArray)
      setOptions(updatedOptions)
    }
  }
  
  const removeOption = (index: number) => {
    // Get the option to be removed from newArrayOptions
    const removedOptionText = newArrayOptions[index].answer
  
    // Only proceed if there is a valid option to remove
    if (removedOptionText) {
      // Clear the selected answer from newArrayOptions at the given index
      const updatedArrayOptions = newArrayOptions.map((part, i) => {
        if (i === index) {
          return { ...part, answer: '' } // Clear the answer for this slot
        }
        return part
      })
  
      // Find the original option in the options list (based on the answer text)
      const removedOption = options.find(option => option.options === removedOptionText)
  
      // If the removed option is not already in options, find it in the question's option list and restore it
      if (!removedOption) {
        // Find the original option in the list of options related to the question
        const originalOption = originalOptions.find(option => option.options === removedOptionText)
  
        if (originalOption) {
          // Restore the original option back to the available options
          setOptions((prevOptions) => [...prevOptions, originalOption])
        }
      }
  
      // Update the state for newArrayOptions
      setNewArrayOptions(updatedArrayOptions)
    }
  }

  const formattedTime = `${timer.minutes.toString().padStart(2, '0')}:${timer.seconds.toString().padStart(2, '0')}`
  // Function to calculate the total elapsed time in seconds
  const calculateElapsedTime = () => {
    return elapsedTimeRef.current
  }

  // Function to count filled options
  const countFilledOptions = () => {
    return newArrayOptions.filter(part => part.answer !== '').length
  }

  const getAllAnswers = () => {
    return newArrayOptions
      .filter(part => part.answer !== '') // Only include parts with answers
      .map(part => ({
        data: part.data, // Return the `data` field
        answer: part.answer // Optionally, include the answer if you need it
      }))
  }

  const replacePlaceholders = (originalDescription: string, answers: string[]): string => {
    return answers.reduce((updatedText, answer, index) => {
      // Construct the placeholder for each answer (e.g., @@^^!!1@@^^!!)
      const placeholder = `@@^^!!${index + 1}@@^^!!`
      // Replace each placeholder with the corresponding answer from answers array
      return updatedText.replace(placeholder, `<span class="text-blue-600 bg-yellow-200">${answer.trim()}</span>`)
    }, originalDescription)
  }

  const handleSubmitAnswer = async() => {
    setIsSubmitted(true)
    const elapsedTime = calculateElapsedTime()

    // Collect all the answers from newArrayOptions
    const allAnswers = getAllAnswers()

    // Prepare to hold answers with their correct values
    const answersWithCorrectValues = allAnswers.map(answer => {
      // Find the original option that matches the user's answer
      const matchingOption = originalOptions.find(option => option.options.trim() === answer.answer.trim())

      return {
        data: answer.data,
        userAnswer: answer.answer,
        correctValue: matchingOption ? matchingOption.correct : 0 
      }
    })
    
    const userAnswerArray = answersWithCorrectValues.map(answer => answer.userAnswer)
    const userAnswer = answersWithCorrectValues.map(answer => answer.correctValue).join('<#>')
    const userAnswerStr = replacePlaceholders(originalDescription, userAnswerArray)
    
    if (question && userProfile && userProfile.userId && buyId) {
      try {
        const addResultResponse = await addResultReadingMock(
          question.description,
          userProfile.userId,
          question.testId,
          question.id,
          question.categoryId,
          userAnswer,
          userAnswerStr,
        )
      
        if (addResultResponse.data.success) {
          const getQuestionResponse = await doTest1Update(
            buyId,
            question.testId,
            userProfile.userId,
            questionNumber,
            3,
            String(elapsedTime),
            String(elapsedTime),
            totalQuestion,
            'Skip'
          )
      
          if (getQuestionResponse.data.success) {
            localStorage.setItem(
              'resultMockPTE',
              JSON.stringify(getQuestionResponse.data.result),
            )

            if (getQuestionResponse.data.category_id === '15') {
              navigate(0) // Reload the same page
            } else {
              navigateToMockTest(
                navigate,
                getQuestionResponse.data.category_id,
              )
            }
          } else {
            console.error(getQuestionResponse.data.message)
          }
        } else {
          console.error(addResultResponse.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question or selected options or buyId not found!')
    }
  }

  const handleSkip = async () => {
    setIsSubmitted(true)
    const elapsedTime = calculateElapsedTime()

    if (question && userProfile && userProfile.userId && buyId) {
      try {
        const getQuestionResponse = await doTest1Update(
          buyId,
          question.testId,
          userProfile.userId,
          questionNumber,
          3,
          String(elapsedTime),
          String(elapsedTime),
          totalQuestion,
          'Skip'
        )
      
        if (getQuestionResponse.data.success) {
          localStorage.setItem(
            'resultMockPTE',
            JSON.stringify(getQuestionResponse.data.result),
          )

          if (getQuestionResponse.data.category_id === '15') {
            navigate(0) // Reload the same page
          } else {
            navigateToMockTest(
              navigate,
              getQuestionResponse.data.category_id,
            )
          }
        } else {
          console.error(getQuestionResponse.data.message)
        }
      } 
      catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question or buyId not found!')
    }
  }

  const filledOptionsCount = countFilledOptions()
  const isSaveDisabled = filledOptionsCount < totalOptionsInParagraph// Determine if the Save button should be disabled

  return (
    <div className='min-h-[80vh]'>
      {
        isLoading ? (
          <LoadingMessage message='Loading question...' /> 
        ) : errorMsg ? (
          <ErrorMessage message={errorMsg} />
        ) : (
          <div>
            <div className="p-8 rounded-xl shadow mt-8">
              <div className="flex justify-between p-4 mb-4 bg-info rounded-lg text-white text-h5r">
                <p>Reading Section - Fill In The Blanks (#{question?.id})</p>
                <p>({questionNumber - 28} Out of 16)</p>
              </div>
              <div className="w-[6.25rem] h-[3.5rem] bg-primary-2 mb-6 rounded-lg flex justify-center items-center mx-auto">
                <p className="text-h2 text-white">{formattedTime}</p>
              </div>
              <p className="text-h4m text-neutrals-1">
                { question?.shortTitle } 
              </p>
              <div className="my-4">
                <div ref={containerParagraphRef}>
                  {newArrayOptions.map((part, index) => part.type === 1 ? (
                    <div
                      key={index}
                      onDrop={(e) => handleDrop(e, index)}
                      onDragOver={handleDragOver}
                      className="min-w-[100px] min-h-[40px] text-center content-center mx-2 mt-1 border border-[1px] border-neutrals-2 rounded-lg inline-block bg-white px-2"
                    >
                      {part.answer ? (
                        <div className="flex justify-between items-center">
                          <span>{part.answer}</span>
                          <button
                            onClick={() => removeOption(index)}
                            className="ml-2 text-red-500 hover:text-red-700"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        <span className="text-placeholder"></span> // Show placeholder
                      )}
                    </div>
                  ) : (
                    <span key={index} className="text-bodyr">{part.data}</span>
                  ))}
                </div>
              </div>
              <div className="flex flex-wrap gap-4 text-blue-500 border border-[1px] lg:p-8 p-4 rounded-md">
                {options.map((option) => (
                  <div
                    key={option.id}
                    draggable
                    onDragStart={(e) => handleDragStart(e, String(option.id), 'items')}
                    className={`text-bodyr bg-[#F5F5F5] border border-[1px]-[#0000000F] text-neutrals-1 hover:text-white hover:bg-info px-6 py-2 rounded-md cursor-pointer`}
                  >
                    {option.options}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex w-full place-content-center mx-auto mt-4">
              <button 
                className={`flex text-bodyr text-white items-center justify-centers gap-2 py-2 px-6 rounded-lg mr-2 
                  ${(isSubmitted|| isSaveDisabled) ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
                onClick={() => handleSubmitAnswer()}
                disabled={isSubmitted ||isSaveDisabled}
              >
                Save and Next
                <img src={NextArrowSvg} alt="next" className="md:ml-2" />
              </button>
              <button 
                className={`flex items-center px-4 py-2 rounded-xl 
                  ${isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-info cursor-pointer'}`}
                disabled={isSubmitted}
                onClick={() => handleSkip()}
              >
                <p className="text-bodyr text-white">Skip</p>
              </button>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default MockReadingFillInTheBlanksQuestion
